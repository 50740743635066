import React from "react";
import SingleRowHorizontal from "./post-previews/SingleRowHorizontal";
import SingleRowVertical from "./post-previews/SingleRowVertical";
import DoubleRow from "./post-previews/DoubleRow";
import TripleRow from "./post-previews/TripleRow";
import styled from "styled-components";

const Wrapper = styled.div`
  &.first-row-only {
    > :not(:first-child) {
      margin-bottom: 0;
    }
  }
`;

export default function FeaturedPostsGrid({
  firstRow = "",
  secondRow = "",
  remainingRows = "",
  posts = [],
  authorTop = false,
  readMoreLink = false,
  onAuthorPage = false,
}) {
  let firstRowPreviewsCount = 0;
  let secondRowPreviewsCount = 0;

  let Row1;
  let Row2;
  let RemainingRows;

  switch (firstRow) {
    case "one-column-horizontal":
    default:
      firstRowPreviewsCount = 1;
      Row1 = (
        <SingleRowHorizontal
          posts={posts}
          startIndex={0}
          endIndex={firstRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "one-column-vertical":
      firstRowPreviewsCount = 1;
      Row1 = (
        <SingleRowVertical
          posts={posts}
          startIndex={0}
          endIndex={firstRowPreviewsCount}
          authorTop={authorTop}
          readMoreLink={readMoreLink}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "two-columns":
      firstRowPreviewsCount = 2;
      Row1 = (
        <DoubleRow
          posts={posts}
          startIndex={0}
          endIndex={firstRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "three-columns":
      firstRowPreviewsCount = 3;
      Row1 = (
        <TripleRow
          posts={posts}
          startIndex={0}
          endIndex={firstRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
  }

  switch (secondRow) {
    case "one-column-horizontal":
    default:
      secondRowPreviewsCount = 1;
      Row2 = (
        <SingleRowHorizontal
          posts={posts}
          startIndex={firstRowPreviewsCount}
          endIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "one-column-vertical":
      secondRowPreviewsCount = 1;
      Row2 = (
        <SingleRowVertical
          posts={posts}
          startIndex={firstRowPreviewsCount}
          endIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          authorTop={authorTop}
          readMoreLink={readMoreLink}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "two-columns":
      secondRowPreviewsCount = 2;
      Row2 = (
        <DoubleRow
          posts={posts}
          startIndex={firstRowPreviewsCount}
          endIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "three-columns":
      secondRowPreviewsCount = 3;
      Row2 = (
        <TripleRow
          posts={posts}
          startIndex={firstRowPreviewsCount}
          endIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
  }

  switch (remainingRows) {
    case "one-column-horizontal":
    default:
      RemainingRows = (
        <SingleRowHorizontal
          posts={posts}
          startIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "one-column-vertical":
      RemainingRows = (
        <SingleRowVertical
          posts={posts}
          startIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          authorTop={authorTop}
          readMoreLink={readMoreLink}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "two-columns":
      RemainingRows = (
        <DoubleRow
          posts={posts}
          startIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
    case "three-columns":
      RemainingRows = (
        <TripleRow
          posts={posts}
          startIndex={firstRowPreviewsCount + secondRowPreviewsCount}
          onAuthorPage={onAuthorPage}
        />
      );
      break;
  }

  return (
    <Wrapper
      className={
        (posts.length === 1 && firstRow === "one-column-horizontal") ||
        (posts.length === 1 && firstRow === "one-column-vertical") ||
        (posts.length === 2 && firstRow === "two-columns") ||
        (posts.length === 3 && firstRow === "three-columns")
          ? "first-row-only"
          : null
      }
    >
      {Row1}
      {Row2}
      {RemainingRows}
    </Wrapper>
  );
}
