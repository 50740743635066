import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import styled from "styled-components";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { MaxWidth, PageDivider, HideOnMobile } from "../components/ui-components";
import FeaturedPostsGrid from "../components/FeaturedPostsGrid";
import SingleRowVertical from "../components/post-previews/SingleRowVertical";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 8
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt(formatString: "MMM DD, YYYY")
          mainImage {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              id
              name
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          slug {
            current
          }
        }
      }
    }
    sanityHomepageSettings {
      featuredPostsLayout {
        firstRow
        secondRow
        remainingRows
      }
      posts {
        _id
        title
        publishedAt(formatString: "MMM DD, YYYY")
        slug {
          current
        }
        mainImage {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        authors {
          author {
            name
            id
            image {
              asset {
                fixed(width: 36) {
                  ...GatsbySanityImageFixed_noBase64
                }
              }
            }
            slug {
              current
            }
          }
        }
        _rawExcerpt(resolveReferences: { maxDepth: 5 })
        _rawBody(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

const TitleWrapper = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 0 auto;

  .title {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.68);
    margin: 1.25rem 0;
  }
`;

const IndexPage = (props) => {
  const { data, location } = props;

  const site = (data || {}).site;
  const { firstRow, secondRow, remainingRows } = (
    data || {}
  ).sanityHomepageSettings.featuredPostsLayout;
  const featuredPosts = (data || {}).sanityHomepageSettings.posts;

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const LatestPosts =
    postNodes.length !== 0 ? (
      <SingleRowVertical
        posts={postNodes}
        authorTop={true}
        readMoreLink={true}
        className={"border-bottom"}
      />
    ) : null;

  return (
    <Layout location={location.pathname}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <MaxWidth>
        <h1 hidden>Welcome to {site.title}</h1>

        <FeaturedPostsGrid
          firstRow={firstRow}
          secondRow={secondRow}
          remainingRows={remainingRows}
          posts={featuredPosts}
        />

        <TitleWrapper>
          <HideOnMobile>
            <PageDivider />
          </HideOnMobile>
          <h2 className={"title"}>Latest Stories</h2>
        </TitleWrapper>
        {LatestPosts}
      </MaxWidth>
    </Layout>
  );
};

export default IndexPage;
