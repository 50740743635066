import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Author from "../Author";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { truncateExcerpt } from "../../lib/helpers";
import { PostPreviewTitle, PostPreviewExcerpt } from "../ui-components";

const SingleOuterWrapper = styled.div`
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0785);
  }
`;

const BackgroundImageWrapper = styled.div`
  height: 275px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 1.25rem;

  .background-image {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 769px) {
    &.two-col-single {
      height: 275px;
    }

    &.three-col-single {
      height: 200px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 300px;
  }

  @media only screen and (max-width: 480px) {
    height: 215px;
  }
`;

const ContentWrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

const PLACEHOLDER_IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "calpoly-logo-vertical.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default function SinglePostPreview({ post, format, onAuthorPage = false }) {
  const imageData = post.mainImage ? post.mainImage.asset.fluid : null;
  const slug = post.slug ? post.slug.current : null;
  const publishedAt = post.publishedAt ? post.publishedAt : null;
  const title = post.title ? post.title : null;

  const authors = post.authors.map(({ author }) => {
    return (
      <Author
        author={author}
        publishedAt={publishedAt}
        key={author.id}
        onAuthorPage={onAuthorPage}
      />
    );
  });

  let excerpt = null;
  if (post._rawExcerpt) {
    // if there is a an excerpt defined, use that for the excerpt preview
    excerpt = truncateExcerpt(post._rawExcerpt[0].children[0].text);
  } else {
    // if no excpert is defined, find the first block item within the body and truncate and use that instead
    for (let index = 0; index < post._rawBody.length; index++) {
      if (post._rawBody[index]._type === "block") {
        excerpt = truncateExcerpt(post._rawBody[index].children[0].text);
        break;
      }
    }
  }

  return (
    <StaticQuery
      query={PLACEHOLDER_IMAGE_QUERY}
      render={(data) => {
        const palceholderImageData = data.file.childImageSharp.fluid;
        return (
          <SingleOuterWrapper>
            <Link to={`/${slug}/`} aria-label={`View ${title} Post`}>
              <BackgroundImageWrapper className={format}>
                <BackgroundImage
                  className={"background-image"}
                  fluid={imageData || palceholderImageData}
                  backgroundColor={`#bcbcbc`}
                />
              </BackgroundImageWrapper>
            </Link>
            <ContentWrapper>
              <Link to={`/${slug}/`}>
                <PostPreviewTitle>{title}</PostPreviewTitle>
              </Link>
              <Link to={`/${slug}/`}>
                {excerpt && <PostPreviewExcerpt>{excerpt}</PostPreviewExcerpt>}
              </Link>
              {authors}
            </ContentWrapper>
          </SingleOuterWrapper>
        );
      }}
    />
  );
}
