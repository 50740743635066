import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Author from "../Author";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { truncateExcerpt } from "../../lib/helpers";
import { PostPreviewTitle, PostPreviewExcerpt } from "../ui-components";

const HorizontalOuterWrapper = styled.div`
  margin-bottom: 1.25rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1.25rem;
  min-height: 375px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0785);
  }
`;

const BackgroundImageWrapper = styled.div`
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);

  .background-image {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    height: 300px;
  }

  @media only screen and (max-width: 480px) {
    height: 215px;
  }
`;

const ContentWrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

const PLACEHOLDER_IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "calpoly-logo-vertical.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default function SingleHorizontal({
  posts,
  startIndex = 0,
  endIndex,
  onAuthorPage = false,
}) {
  const slicedPosts = endIndex ? posts.slice(startIndex, endIndex) : posts.slice(startIndex);

  return slicedPosts.map((post) => {
    const imageData = post.mainImage ? post.mainImage.asset.fluid : null;
    const slug = post.slug ? post.slug.current : null;
    const publishedAt = post.publishedAt ? post.publishedAt : null;
    const title = post.title ? post.title : null;

    let excerpt = null;
    if (post._rawExcerpt) {
      // if there is a an excerpt defined, use that for the excerpt preview
      excerpt = truncateExcerpt(post._rawExcerpt[0].children[0].text);
    } else {
      // if no excpert is defined, find the first block item within the body and truncate and use that instead
      for (let index = 0; index < post._rawBody.length; index++) {
        if (post._rawBody[index]._type === "block") {
          excerpt = truncateExcerpt(post._rawBody[index].children[0].text);
          break;
        }
      }
    }

    const authors = post.authors.map(({ author }) => {
      return (
        <Author
          author={author}
          publishedAt={publishedAt}
          key={author.id}
          onAuthorPage={onAuthorPage}
        />
      );
    });

    return (
      <StaticQuery
        query={PLACEHOLDER_IMAGE_QUERY}
        render={(data) => {
          const palceholderImageData = data.file.childImageSharp.fluid;
          return (
            <HorizontalOuterWrapper key={post._id || post.id}>
              <Link to={`/${slug}/`} aria-label={`View ${title} Post`}>
                <BackgroundImageWrapper>
                  <BackgroundImage
                    className={"background-image"}
                    fluid={imageData || palceholderImageData}
                    backgroundColor={`#bcbcbc`}
                  />
                </BackgroundImageWrapper>
              </Link>
              <ContentWrapper>
                <Link to={`/${slug}/`}>
                  <PostPreviewTitle>{title}</PostPreviewTitle>
                </Link>
                <Link to={`/${slug}/`}>
                  {excerpt && <PostPreviewExcerpt>{excerpt}</PostPreviewExcerpt>}
                </Link>
                {authors}
              </ContentWrapper>
            </HorizontalOuterWrapper>
          );
        }}
      />
    );
  });
}
