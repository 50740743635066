import React from "react";
import SinglePostPreview from "./SinglePostPreview";
import styled from "styled-components";

const DoubleColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  margin-bottom: 1.25rem;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export default function Double({ posts, startIndex = 0, endIndex, onAuthorPage = false }) {
  const postPreviews = endIndex
    ? posts.slice(startIndex, endIndex).map((post) => {
        return (
          <SinglePostPreview
            post={post}
            key={post._id}
            format={"two-col-single"}
            onAuthorPage={onAuthorPage}
          />
        );
      })
    : posts.slice(startIndex).map((post) => {
        return (
          <SinglePostPreview
            post={post}
            key={post._id}
            format={"two-col-single"}
            onAuthorPage={onAuthorPage}
          />
        );
      });

  return <DoubleColumnWrapper>{postPreviews}</DoubleColumnWrapper>;
}
