import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Author from "../Author";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { truncateExcerpt } from "../../lib/helpers";
import { PostPreviewTitle, PostPreviewExcerpt } from "../ui-components";

const VerticalOuterWrapper = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1.25rem;

  @media only screen and (max-width: 768px) {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0785);
  }

  &.border-bottom:not(:last-child) {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0785);
  }
`;

const BackgroundImageWrapper = styled.div`
  height: 375px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 1.25rem;

  .background-image {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    height: 300px;
  }

  @media only screen and (max-width: 480px) {
    height: 215px;
  }
`;

const ContentWrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

const ReadMore = styled.p`
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;

  a {
    color: rgba(0, 0, 0, 0.54);

    transition: color 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

    &:visited {
      color: rgba(0, 0, 0, 0.54);
    }
    &:hover,
    &:active,
    &:focus {
      color: rgba(0, 0, 0, 0.68);
    }
  }

  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const PLACEHOLDER_IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "calpoly-logo-vertical.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default function SingleVertical({
  posts,
  startIndex = 0,
  endIndex,
  authorTop = false,
  readMoreLink = false,
  className = "",
  onAuthorPage = false,
}) {
  const slicedPosts = endIndex ? posts.slice(startIndex, endIndex) : posts.slice(startIndex);

  return slicedPosts.map((post) => {
    const imageData = post.mainImage ? post.mainImage.asset.fluid : null;
    const slug = post.slug ? post.slug.current : null;
    const publishedAt = post.publishedAt ? post.publishedAt : null;
    const title = post.title ? post.title : null;

    const authors = post.authors.map(({ author }) => {
      return (
        <Author
          author={author}
          publishedAt={publishedAt}
          key={author.id}
          onAuthorPage={onAuthorPage}
        />
      );
    });

    let excerpt = null;
    if (post._rawExcerpt) {
      // if there is a an excerpt defined, use that for the excerpt preview
      excerpt = truncateExcerpt(post._rawExcerpt[0].children[0].text);
    } else {
      // if no excpert is defined, find the first block item within the body and truncate and use that instead
      for (let index = 0; index < post._rawBody.length; index++) {
        if (post._rawBody[index]._type === "block") {
          excerpt = truncateExcerpt(post._rawBody[index].children[0].text);
          break;
        }
      }
    }

    return (
      <StaticQuery
        query={PLACEHOLDER_IMAGE_QUERY}
        render={(data) => {
          const palceholderImageData = data.file.childImageSharp.fluid;
          return (
            <VerticalOuterWrapper className={className} key={post.id || post._id}>
              {authorTop ? <div style={{ marginBottom: "1.25rem" }}>{authors}</div> : null}
              <Link to={`/${slug}/`} aria-label={`View ${title} Post`}>
                <BackgroundImageWrapper>
                  <BackgroundImage
                    className={"background-image"}
                    fluid={imageData || palceholderImageData}
                    backgroundColor={`#bcbcbc`}
                  />
                </BackgroundImageWrapper>
              </Link>
              <ContentWrapper>
                <Link to={`/${slug}/`}>
                  <PostPreviewTitle>{title}</PostPreviewTitle>
                </Link>
                <Link to={`/${slug}/`}>
                  {excerpt && <PostPreviewExcerpt>{excerpt}</PostPreviewExcerpt>}
                </Link>
                {readMoreLink ? (
                  <ReadMore>
                    <Link to={`/${slug}/`}>Read more...</Link>
                  </ReadMore>
                ) : null}
                {!authorTop ? authors : null}
              </ContentWrapper>
            </VerticalOuterWrapper>
          );
        }}
      />
    );
  });
}
